import '../styles/contact-section.scss';

import { Button, Typography } from 'antd';
import React, { memo } from 'react';

import { useConfigData, useContactSectionData } from '../data/hooks';
import { makeThemeClassName } from '../utils';
import Form from './form';
import { Box, Col, Container, Row, Section } from './layout';
import SocialMedia from './social-media';

interface ContactSectionProps {
  id: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({ id }) => {
  const { data } = useContactSectionData(id);
  const { data: config } = useConfigData().fallback;

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'contact-section': true,
        'section-padding': true,
      })}
    >
      <Container>
        <Row>
          <Col>
            <Typography.Title level={3}>{data.title}</Typography.Title>

            <Box className="contact-section__form-box">
              <Form id={data.form.id} />
            </Box>
          </Col>

          <Col
            style={{
              md: { paddingLeft: '6rem' },
            }}
          >
            <Box justify="center" direction="column" fullHeight>
              <Box className="contact-section__social-media-box">
                <Typography.Paragraph>Zadzwoń</Typography.Paragraph>
                <Button
                  type="link"
                  href={`tel:${config.phoneNumber}`}
                  size="large"
                  block={false}
                >
                  {config.formattedPhoneNumber}
                </Button>
              </Box>

              <Box className="contact-section__social-media-box">
                <Typography.Paragraph>Napisz e-mail</Typography.Paragraph>
                <Button
                  type="link"
                  href={`mailto:${config.email}`}
                  size="large"
                >
                  {config.email}
                </Button>
              </Box>

              <Box className="contact-section__social-media-box">
                <Typography.Paragraph>Social media</Typography.Paragraph>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Bądź na bieżąco
                </Typography.Title>
                <Typography.Paragraph>
                  #oyakademiaurody - polub, śledź, i nie przegap wydarzeń - Bądz
                  z nami na dedykowanych kanałach social media:
                </Typography.Paragraph>
                <SocialMedia />
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default memo(ContactSection);
