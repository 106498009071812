/* eslint-disable simple-import-sort/sort */
import '../styles/grid-list-item.scss';

import { Typography } from 'antd';
import React from 'react';

import { useGridListItemData, ImageData } from '../data';
import Image from './image';
import { Box } from './layout';

interface GridListItemProps {
  id: string;
  index: number;
}

interface GridListItemComponentProps {
  title: string;
  description?: string;
  image?: ImageData;
  index?: number;
}

export const GridListItemComponent: React.FC<GridListItemComponentProps> = ({
  image,
  description,
  title,
  index,
}) => (
  <Box justify="center" align="middle" direction="column">
    <div className="grid-list-item__image">
      {image ? (
        <Image id={image.id} />
      ) : (
        <div className="grid-list-item__index">{index + 1}</div>
      )}
    </div>

    <Typography.Title level={4} style={{ textAlign: 'center' }}>
      {title}
    </Typography.Title>

    <Typography.Paragraph style={{ textAlign: 'center' }}>
      {description}
    </Typography.Paragraph>
  </Box>
);

export const GridListItem: React.FC<GridListItemProps> = ({ id, index }) => {
  const { data } = useGridListItemData(id);

  return <GridListItemComponent {...data} index={index} />;
};

export default GridListItem;
