// TODO replace it with hooks approach
export const post = async (url: string, payload: unknown): Promise<void> => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { Accept: 'application/json' },
    body: JSON.stringify(payload),
  });

  await response.json();
};
