import { useNavigate } from '@reach/router';
import { Button, Typography } from 'antd';
import React from 'react';

import { ServiceType, useServiceSectionData } from '../data';
import { useTrainingDetailsSectionData } from '../data/hooks';
import { useTreatmentDetailsSectionData } from '../data/hooks/use-treatment-details-section-data';
import { useServicePagesPartial } from '../hooks';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import Cards from './common/cards';
import SectionHeading from './common/section-heading';
import { Box, Container, Section } from './layout';

interface ServiceSectionProps {
  id: string;
}

const CTA_BUTTON_LABEL = {
  [ServiceType.Trainings]: 'Zapisz się na szkolenie',
  [ServiceType.Treatments]: 'Dowiedz się więcej',
};

const ServiceSection: React.FC<ServiceSectionProps> = ({ id }) => {
  const { data } = useServiceSectionData(id);

  const navigate = useNavigate();

  const servicePagesPartial = useServicePagesPartial();

  const servicesDetails = {
    [ServiceType.Treatments]: useTreatmentDetailsSectionData()?.list,
    [ServiceType.Trainings]: useTrainingDetailsSectionData()?.list,
  };

  const filteredServices = servicesDetails[data.serviceType].filter(
    service => service.data.serviceId === data.serviceId,
  );

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'theme-offwhite': true,
        'section-padding': true,
      })}
    >
      <Container>
        <SectionHeading title={data.title} description={data.description} />

        <Cards
          align="start"
          items={filteredServices.map(item => ({
            subTitle: item.data.caption,
            title: item.data.title,
            content: (
              <Box>
                <Typography.Title level={4}>{item.data.price}</Typography.Title>

                {contentfulJSONToReactComponents(
                  item.data.shortDescription.json,
                )}
              </Box>
            ),
            renderButton: props => {
              let navigateTo;

              if (data.serviceType === ServiceType.Trainings) {
                // TODO temporary path to determine path to a proper training, in future release needs to be automated and come from page config
                navigateTo = item.data.temporaryPath;
              }

              if (data.serviceType === ServiceType.Treatments) {
                navigateTo =
                  servicePagesPartial?.[data.serviceType]?.[data.serviceId]?.[0]
                    ?.path;
              }

              return (
                navigateTo && (
                  <Button
                    onClick={() => navigate(navigateTo)}
                    {...props}
                    size="large"
                  >
                    {CTA_BUTTON_LABEL[data.serviceType]}
                  </Button>
                )
              );
            },
          }))}
        />
      </Container>
    </Section>
  );
};

export default ServiceSection;
