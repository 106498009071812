import '../styles/interleaving-images-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useTreatmentDetailsSectionData } from '../data';
import { makeThemeClassName } from '../utils';
import { contentfulJSONToReactComponents } from '../utils/contentful';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';

interface TreatmentDetailsSectionProps {
  id: string;
}

const TreatmentDetailsSection: React.FC<TreatmentDetailsSectionProps> = ({
  id,
}) => {
  const data = useTreatmentDetailsSectionData(id);
  const occupiedIndex = useTreatmentDetailsSectionData()?.list?.findIndex(
    item => item.id === id,
  );

  const indexIsOdd = occupiedIndex % 2 !== 0;

  return (
    <Section
      className={makeThemeClassName(data?.data?.theme, {
        'interleaving-images-section': true,
        'theme-offwhite': true,
        'section-padding': true,
      })}
    >
      <Container>
        <Row className="interleaving-images-section__item">
          <Col
            span={{ sm: 24, lg: 12 }}
            order={{ sm: 1, lg: indexIsOdd ? 2 : 1 }}
          >
            <Image id={data?.data?.image?.id} />
          </Col>

          <Col
            span={{ sm: 24, lg: 12 }}
            order={{ sm: 2, lg: indexIsOdd ? 1 : 2 }}
            style={{
              padding: '0 2rem',
            }}
          >
            <Box justify="center" direction="column" fullHeight>
              <Typography.Title level={3} className="text-uppercase">
                {data?.data?.title}
              </Typography.Title>
              {contentfulJSONToReactComponents(data?.data?.description.json)}
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default TreatmentDetailsSection;
