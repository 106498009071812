import { Typography } from 'antd';
import React from 'react';

import { useSalesSectionItemData } from '../data/hooks';
import { contentfulJSONToReactComponents } from '../utils';
import Button from './button';
import Image from './image';
import { Box, Col, Row } from './layout';

interface SalesSectionItemProps {
  id: string;
}

const SalesSectionItem: React.FC<SalesSectionItemProps> = ({ id }) => {
  const { data } = useSalesSectionItemData(id);

  return (
    <Row>
      <Col className="sales-section__description-col" span={{ sm: 24, lg: 12 }}>
        <Box fullHeight direction="column">
          {data.companyLogo && <Image id={data.companyLogo.id} />}

          <Box className="sales-section__description-col-heading">
            <Typography.Paragraph className="sales-section__subtitle">
              {data.subTitle}
            </Typography.Paragraph>

            <Typography.Title level={3} style={{ margin: 0 }}>
              {data.title}
            </Typography.Title>

            {contentfulJSONToReactComponents(data.description.json)}

            {data.button && <Button id={data.button.id} />}
          </Box>
        </Box>
      </Col>

      <Col
        hidden={!data.image}
        className="sales-section__image-col"
        span={{ sm: 24, lg: 12 }}
      >
        <Image id={data.image.id} />
      </Col>
    </Row>
  );
};

export default SalesSectionItem;
