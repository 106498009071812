/* eslint-disable simple-import-sort/sort */
import '../styles/numbers-list.scss';

import { Typography } from 'antd';
import React from 'react';

import { useNumbersListData, NumbersListItemData } from '../data';
import CounterUp from './counter-up';
import { Box, Col, Row } from './layout';

interface NumbersListProps {
  id: string;
}

interface NumbersListComponentProps {
  columns: number;
  items: NumbersListItemData[];
}

const NumbersListComponent: React.FC<NumbersListComponentProps> = ({
  items,
  columns,
}) => {
  const colSpan = 24 / columns;

  return (
    <Row gutters="2rem" className="numbers-list">
      {items.map((item, index) => (
        <Col span={{ sm: 24, lg: colSpan }} key={index}>
          <Box justify="center" align="middle" direction="column">
            <Typography.Title level={3}>
              <CounterUp end={item.data.count} suffix={item.data.unit} />
            </Typography.Title>
            <Typography.Paragraph>{item.data.description}</Typography.Paragraph>
          </Box>
        </Col>
      ))}
    </Row>
  );
};

export const NumbersList: React.FC<NumbersListProps> = ({ id }) => {
  const { data } = useNumbersListData(id);

  return <NumbersListComponent {...data} />;
};

export default NumbersList;
