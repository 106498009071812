import '../styles/form.scss';

import { Form as AntdForm, notification } from 'antd';
import React, { useState } from 'react';

import { useFormData } from '../data/hooks/use-form-data';
import { win } from '../fragments';
import { post } from '../rest';
import Button from './button';
import FormField from './form-field';

interface FormProps {
  id: string;
  onSuccess?: () => void;
}

const Form: React.FC<FormProps> = ({ id, onSuccess }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const {
    data: {
      title,
      description,
      fields,
      successMessage,
      errorMessage,
      submitButton,
      actionUrl,
    },
  } = useFormData(id);

  const [form] = AntdForm.useForm();

  const handleFormSubmit = async fieldsValues => {
    await form?.validateFields();

    try {
      setFormLoading(true);
      await post(
        actionUrl,
        { ...fieldsValues, location: win?.location.href } ?? {},
      );
      setFormLoading(false);

      if (onSuccess) {
        onSuccess();
      }

      notification.success({ message: successMessage ?? 'Success' });
    } catch {
      setFormLoading(false);
      notification.error({ message: errorMessage ?? 'Error' });
    }
  };

  return (
    <>
      {title && <h3>{title}</h3>}
      {description && <p>{description}</p>}

      <AntdForm form={form} onFinish={handleFormSubmit} layout="vertical">
        {fields.map((field, index) => (
          <FormField id={field.id} key={index} />
        ))}

        {submitButton?.id && (
          <AntdForm.Item>
            <Button
              id={submitButton.id}
              defaultProps={{
                htmlType: 'submit',
                type: 'primary',
                loading: formLoading,
              }}
            />
          </AntdForm.Item>
        )}
      </AntdForm>
    </>
  );
};

export default Form;
