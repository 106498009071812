import { useNavigate } from '@reach/router';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import React, { useState } from 'react';

import { useButtonData } from '../data/hooks';
import { useLinkResolver } from '../hooks/use-link-resolver';
import { omit } from '../utils/object';
import Image from './image';
import Modal from './modal';

export interface ButtonProps {
  id: string;
  defaultProps?: AntdButtonProps;
}

const Button: React.FC<ButtonProps> = ({ id, defaultProps }) => {
  const [modalId, setModalId] = useState<symbol>();
  const navigate = useNavigate();

  const { data } = useButtonData(id);

  const resolveLink = useLinkResolver();

  const handleClick = () => {
    if (data.link) {
      navigate(resolveLink(data.link));
    }

    if (data.modalId) {
      setModalId(Symbol('id'));
    }
  };

  return (
    <>
      <AntdButton
        onClick={handleClick}
        {...omit(data, ['modalData', 'label', 'link', 'startIcon', 'endIcon'])}
        {...defaultProps}
        size="large"
      >
        {data.startIcon && <Image id={data.startIcon.id} />}

        {data.label}

        {data.endIcon && <Image id={data.endIcon.id} />}
      </AntdButton>

      {data.modalId && <Modal id={data.modalId} modalId={modalId} />}
    </>
  );
};

export default Button;
