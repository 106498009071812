import React from 'react';
import { CountUpProps, default as CountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

type Props = CountUpProps;

const CounterUp: React.FC<Props> = props => {
  let shouldDraw = true;

  return (
    <CountUp start={0} {...props}>
      {({ countUpRef, start }) => (
        <VisibilitySensor
          onChange={visible => {
            if (
              shouldDraw === false ||
              visible === false ||
              start === undefined
            ) {
              return;
            }

            shouldDraw = props.redraw === true;

            start();
          }}
          delayedCall
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default CounterUp;
