import '../styles/cta-section.scss';

import React, { memo } from 'react';

import { useCtaSectionData } from '../data';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import Button from './button';
import { Box, Section } from './layout';

interface CtaSectionProps {
  style?: unknown;
  id?: string;
}

const CtaSection: React.FC<CtaSectionProps> = ({ id, style }) => {
  const { data } =
    id === undefined ? useCtaSectionData().fallback : useCtaSectionData(id);

  return (
    <Section
      style={style}
      className={makeThemeClassName(data.theme, {
        'cta-section': true,
        'theme-secondary': true,
      })}
    >
      <Box justify="center" align="middle" direction="column">
        <h2>{data.title}</h2>
        {contentfulJSONToReactComponents(data.description.json)}
        <Button id={data.button.id} />
      </Box>
    </Section>
  );
};

export default memo(CtaSection);
