import { Typography } from 'antd';
import React from 'react';

import { useGallerySectionItemImageData } from '../data/hooks';
import Image from './image';

interface GallerySectionItemImageProps {
  id: string;
}

const GallerySectionItemImage: React.FC<GallerySectionItemImageProps> = ({
  id,
}) => {
  const { data } = useGallerySectionItemImageData(id);

  return (
    <>
      <Image id={data.image.id} />
      {data.title && <Typography.Paragraph>{data.title}</Typography.Paragraph>}
      {data.description && (
        <Typography.Paragraph>{data.description}</Typography.Paragraph>
      )}
    </>
  );
};

export default GallerySectionItemImage;
