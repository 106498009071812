import { Space, Typography } from 'antd';
import React from 'react';

import { useTrainingDetailsSectionData } from '../data';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import Button from './button';
import { Image } from './image';
import { Box, Col, Container, Row, Section } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';
import { LayoutBreakpoint } from './layout/types';

interface TrainingDetailsSectionProps {
  id: string;
}

const TrainingDetailsSection: React.FC<TrainingDetailsSectionProps> = ({
  id,
}) => {
  const data = useTrainingDetailsSectionData(id);

  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  return (
    <Section
      className={makeThemeClassName(data?.data?.theme, {
        'theme-secondary': true,
      })}
    >
      <Container>
        <Row>
          <Col span={{ sm: 24, md: 12 }}>
            <Image id={data?.data?.image?.id} />
          </Col>

          <Col
            style={{ sm: { padding: '2rem 0' }, md: { padding: '2rem' } }}
            span={{ sm: 24, md: 12 }}
          >
            <Box justify="center" direction="column" fullHeight>
              <Typography.Title level={3}>{data?.data?.title}</Typography.Title>

              {contentfulJSONToReactComponents(data?.data?.description.json)}

              <Typography.Title>{data?.data?.price}</Typography.Title>

              {data?.data?.buttons && (
                <Space
                  wrap
                  direction={isMd ? 'horizontal' : 'vertical'}
                  style={{ width: '100%' }}
                >
                  {data?.data?.buttons?.map((button, index) => (
                    <Button
                      id={button.id}
                      key={index}
                      defaultProps={{ block: true }}
                    />
                  ))}
                </Space>
              )}
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default TrainingDetailsSection;
