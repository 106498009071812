import React from 'react';

import { DataType } from '../data/model/common';
import { PageSectionsData } from '../data/model/page';
import AboutSection from './about-section';
import CompareImageSection from './compare-image-section';
import ContactSection from './contact-section';
import CtaSection from './cta-section';
import CustomSection from './custom-section';
import GallerySection from './gallery-section';
import HeroSection from './hero-section';
import InterleavingImagesSection from './interleaving-images-section';
import PricesSection from './prices-section';
import SalesSection from './sales-section';
import ServiceSection from './service-section';
import TrainingDetailsSection from './training-details-section';
import TreatmentDetailsSection from './treatment-details-section';

interface PageProps {
  data?: any;
}

const SECTIONS_COMPONENTS: {
  [TType in PageSectionsData['type']]: React.FC<{ id: string }>;
} = {
  [DataType.ContactSection]: ContactSection,
  [DataType.CtaSection]: CtaSection,
  [DataType.CustomSection]: CustomSection,
  [DataType.HeroSection]: HeroSection,
  [DataType.InterleavingImagesSection]: InterleavingImagesSection,
  [DataType.AboutSection]: AboutSection,
  [DataType.GallerySection]: GallerySection,
  [DataType.SalesSection]: SalesSection,
  [DataType.CompareImageSection]: CompareImageSection,
  [DataType.PricesSection]: PricesSection,
  [DataType.ServiceSection]: ServiceSection,
  [DataType.TrainingDetailsSection]: TrainingDetailsSection,
  [DataType.TreatmentDetailsSection]: TreatmentDetailsSection,
};

const Page: React.FC<PageProps> = ({ data }) => (
  <>
    {data.sections.map((section, index) => {
      const Component = SECTIONS_COMPONENTS[section.type];

      return <Component id={section.id} key={index} />;
    })}
  </>
);

export default Page;
