import '../../styles/video.scss';

import React from 'react';

export interface VideoProps {
  url: string;
  title: string;
}

const Video: React.FC<VideoProps> = ({ url, title }) => (
  <div className="video">
    <video loop muted autoPlay playsInline>
      <source src={url} title={title} type="video/mp4" />
    </video>
  </div>
);

export default Video;
