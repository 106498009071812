import '../styles/compare-image-section.scss';

import { Carousel, Typography } from 'antd';
import React from 'react';
import ReactCompareImage from 'react-compare-image';

import { THEME_CONFIG } from '../config';
import { useCompareImageSectionData } from '../data';
import { win } from '../fragments';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import { Box, Col, Row, Section } from './layout';

interface CompareImageSectionProps {
  id: string;
}

const CompareImageSection: React.FC<CompareImageSectionProps> = ({ id }) => {
  const { data } = useCompareImageSectionData(id);

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'compare-image-section': true,
        'theme-secondary': true,
      })}
    >
      <Carousel swipe={false}>
        {data.items.map((item, index) => (
          <div key={index}>
            <Row>
              <Col span={{ sm: 24, md: 12 }}>
                <Box className="compare-image-section__compare-box">
                  {typeof (win as any)?.ResizeObserver === 'function' && (
                    <ReactCompareImage
                      leftImage={item?.data?.beforeImage?.data?.bitmap?.url}
                      rightImage={item?.data?.afterImage?.data?.bitmap?.url}
                      sliderLineColor={THEME_CONFIG['theme-primary']}
                      leftImageLabel={data?.beforeButtonLabel}
                      rightImageLabel={data?.afterButtonLabel}
                    />
                  )}
                </Box>
              </Col>

              <Col span={{ sm: 24, md: 12 }} style={{ padding: '2rem' }}>
                <Box justify="center" direction="column" fullHeight>
                  <Typography.Title level={3}>
                    {item.data.title}
                  </Typography.Title>
                  {contentfulJSONToReactComponents(item.data.description.json)}
                </Box>
              </Col>
            </Row>
          </div>
        ))}
      </Carousel>
    </Section>
  );
};

export default CompareImageSection;
