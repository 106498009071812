import '../styles/grid.scss';

import React from 'react';

import { useGridData } from '../data';
import { DataType } from '../data/model/common';
import { GridItemData } from '../data/model/grid';
import Image from './image';
import { Col, Row } from './layout';

interface GridProps {
  id: string;
}

const ITEMS_COMPONENTS: {
  [TType in GridItemData['type']]: React.FC<{ id: string }>;
} = {
  [DataType.Image]: Image,
};

export const Grid: React.FC<GridProps> = ({ id }) => {
  const { data } = useGridData(id);

  return (
    <div className="grid">
      <Row gutters="2rem">
        {data.items.map((item, index) => {
          const Component = ITEMS_COMPONENTS[item.type];

          return (
            <Col
              key={index}
              span={{ sm: 24, md: 24 / data.columns }}
              style={{ sm: { marginTop: '2rem' } }}
            >
              <Component id={item.id} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Grid;
