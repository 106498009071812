import '../styles/testimonials.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { useTestimonialsData } from '../data';
import ArrowDownSvg from '../images/arrow-down.svg';
import { Box, Col, Row, useBreakpoint } from './layout';
import { LayoutBreakpoint } from './layout/types';
import Testimonial from './testimonial';

interface TestimonialsProps {
  id: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({ id }) => {
  const [page, setPage] = useState(0);
  const { data } = useTestimonialsData(id);
  const isLarge = useBreakpoint(LayoutBreakpoint.LG);

  const pageSize = isLarge ? 3 : 1;
  const maxPages = useMemo(
    () => Math.ceil(data.testimonials.length / pageSize),
    [isLarge, data],
  );

  const visibleItems = useMemo(
    () => data.testimonials.slice(page * pageSize, page * pageSize + pageSize),
    [pageSize, page],
  );

  useEffect(() => setPage(current => Math.min(maxPages - 1, current)), [
    maxPages,
  ]);

  return (
    <Row className="testimonials">
      <Col span={3}>
        <Box fullHeight align="middle" justify="start">
          <div
            className="testimonials__next-button"
            role="button"
            onClick={() => setPage(current => Math.max(0, current - 1))}
            hidden={page === 0}
          >
            <ArrowDownSvg />
          </div>
        </Box>
      </Col>

      <Col span={18}>
        <Row gutters={['2rem', 0]} align="middle">
          {visibleItems.map((item, index) => (
            <Col span={24 / pageSize} key={index}>
              <Testimonial id={item.id} />
            </Col>
          ))}
        </Row>
      </Col>

      <Col span={3}>
        <Box fullHeight align="middle" justify="end">
          <div
            className="testimonials__prev-button"
            role="button"
            onClick={() =>
              setPage(current => Math.min(maxPages - 1, current + 1))
            }
            hidden={page === maxPages - 1}
          >
            <ArrowDownSvg />
          </div>
        </Box>
      </Col>
    </Row>
  );
};

export default Testimonials;
