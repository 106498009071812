import { Typography } from 'antd';
import React from 'react';

import { useAboutSectionItemData } from '../data';
import Button from './button';
import Image from './image';
import { Box } from './layout';

interface AboutSectionItemProps {
  id: string;
}

const AboutSectionItem: React.FC<AboutSectionItemProps> = ({ id }) => {
  const { data } = useAboutSectionItemData(id);

  return (
    <div className="about-section-item">
      <Box className="about-section-item__image">
        <Image id={data.image.id} />
      </Box>

      <Box className="about-section-item__description">
        <Typography.Title level={4}>{data.name}</Typography.Title>
        <Typography.Paragraph>{data.position}</Typography.Paragraph>
        <Button
          id={data.button.id}
          defaultProps={{
            block: true,
          }}
        />
      </Box>
    </div>
  );
};

export default AboutSectionItem;
