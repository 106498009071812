/* eslint-disable simple-import-sort/sort */
import '../styles/gangsta-lasers.scss';
import '../styles/collapsible-list.scss';

import { Tabs, Typography, Table, List, Collapse } from 'antd';
import React from 'react';
import Img from 'gatsby-image';
import { Box, Col, Row } from './layout';
import Video, { VideoProps } from './common/video';
import { GridListComponent } from './grid-list';
import { GridListItemData } from '../data';
import SectionHeading from './common/section-heading';
import { graphql, useStaticQuery } from 'gatsby';
import { useTabs } from './common/tabs';

interface GangstaLasersRecord {
  id: string;
  title: string;
  video: VideoProps;
  price: string;
  description: string | React.ReactNode;
  benefits: GridListItemData['data'][];
  images: string[];
  mainDesription: any;
  mainContentItems: any[];
  mainExtraContent?: any;
  mainContentItemColumns?: number;
  indications?: { title: string }[];
  specTableRows: { key: string; specName: string; specDetail: string }[];
}

const GANGSTA_LASERS_DATA: GangstaLasersRecord[] = [
  {
    title: 'Laser Gangsta Nano Double Rods',
    id: 'gangsta-nano-double-rods',
    video: {
      title: 'nano krysztal x2',
      url:
        'https://videos.ctfassets.net/684e5sx201ld/4fRtiEhGi8hIbcGNx2D9ya/4291f5178e324f9d287dbbb2a63898cf/nano_krysztal_x2.mp4',
    },
    images: ['nanoX21', 'nanoX22'],
    price: '50 000 zł netto + 23% VAT',
    description: (
      <>
        <Typography.Paragraph>
          <strong>PIERWSZY W POLSCE LASER Z DWOMA KRYSZTAŁAMI!!!</strong> Moc
          lasera stacjonarnego zamknięta w laserze kompaktowym. Zwiększona moc w
          monoimpulsie.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Ideą stworzenia lasera do usuwania pigmentów z podwójnym systemem
          optycznym (czyli z podwójnym kryształem) jest zminimalizowanie strat
          energii przy pracy na rożnych długościach fal.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Dzięki dużej gęstości energii generowanej przez podwójny system
          optyczny jesteśmy w stanie usuwać makijaże permanentne i tatuaże w
          czasie o połowę krótszym, niż w przypadku lasera opartego na
          monokrysztale.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Podwójny system optyczny sprawia, że usuwanie pigmentów zarówno
          organicznych jak i nieorganicznych, nie sprawia operatorowi problemów.
          Duża dawka kontrolowanej energii elektromagnetycznej jest w stanie
          rozbić nawet cząsteczki koloru zamknięte w bliznach.
        </Typography.Paragraph>
      </>
    ),
    benefits: [
      {
        title:
          'Gangsta Double Rods to urządzenie produkowane na nasze zamówienie i zgodnie z naszymi wytycznymi',
      },
      {
        title:
          'Wysokiej jakości dwa kryształy neodymowo – yagowe w połączeniu z mocną ksenonową lampą pozwalają na pracę na stabilnych parametrach podczas całej sesji usuwania',
      },
      { title: 'Technologia Q-Switch & Intuicyjna obsługa, nowoczesny design' },
      {
        title:
          'Intuicyjny układ sterowania laserem bezpośrednio wpływa na jakość pracy, zwiększając ją kilkukrotnie',
      },
      {
        title:
          'Przedłużona żywotność i wydajność urządzenia poprzez zastosowanie wysokiej jakości elementów systemu kontroli i ochrony. Ochrona kryształów w czasie rzeczywistym dzięki wysokiej jakości czujnikom temperatury oraz czujnikom przepływu cieczy',
      },
      {
        title:
          'Wielostopniowy system chłodzenia woda-powietrze o zwiększonej wydajności & Specjalnie zaprojektowana obudowa',
      },
    ],
    mainDesription: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Usuwanie makijaży permanentnych i tatuaży: Laser Gangsta Double Rods skutecznie usuwa tatuaże oraz makijaże permanentne emitując krótkie impulsy światła mierzone w nanosekundach. Impulsy pochłaniane są w sposób selektywny przez barwnik. Następuje niszczenie barwnika, a jego mikrofragmenty są pochłaniane i wydalane przez układ limfatyczny. Dzięki wbudowanym podwójnym kryształom ND-YAG, proces usuwania pigmentu jest prawie dwukrotnie szybszy, niż w przypadku lasera z jednym kryształem. Skuteczny dla wszystkich typów skór. Usuwa pigment na głębokości 5mm. Każdy kolor barwnika absorbuje inną długość fali.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    mainContentItems: [
      {
        title: 'Gangsta Double Rods generuje fale o długości',
        description: (
          <ul>
            <li>1064nm – na wszystkie ciemne kolory</li>
            <li>
              dzięki zastosowaniu kryształu KTP – 532nm – na odcienie czerwieni
            </li>
          </ul>
        ),
      },
      {
        title: 'Usuwanie przebarwień',
        description:
          'Usuwanie przebarwień laserem ND-YAG Q-Switch jest możliwe dzięki wysokiej absorbcji promieni lasera w melaninie.',
      },
      {
        title: 'Peeling węglowy/Carbon peeling',
        description:
          'Zabieg polegający na usunięciu promieniowaniem laserowym ze skóry twarzy, szyi i dekoltu koloidalnej pasty węglowej. Drobinki węgla wnikają w nierówności oraz pory skóry, a następnie absorbują energię światła lasera ND-YAG, która powoduje natychmiastowe odparowanie preparatu wraz z zanieczyszczeniami. Efektem jest oczyszczenie i zwężenie porów skóry, ujednolicenie kolorytu, stymulacja produkcji kolagenu, zmniejszenie stanu zapalnego, stymulacja komórek skóry do odbudowy oraz ustabilizowanie wydzielania sebum.',
      },
    ],
    mainContentItemColumns: 3,
    mainExtraContent:
      'W cenie urządzenia dwudniowe szkolenie z zakresu bezpiecznych technik usuwania makijażu permanentnego i tatuażu oraz peelingu węglowego.',
    specTableRows: [
      {
        key: '1',
        specName: 'Model',
        specDetail: 'Gangsta Double Rods',
      },
      {
        key: '2',
        specName: 'Moc',
        specDetail: '1000W',
      },
      {
        key: '3',
        specName: 'Długość fali',
        specDetail: '1064nm, 532nm',
      },
      {
        key: '4',
        specName: 'Maksymalna energia',
        specDetail: '2000mJ',
      },
      {
        key: '5',
        specName: 'Moc w monoimpulsie',
        specDetail: '350-380mJ',
      },
      {
        key: '6',
        specName: 'Czas trwania impulsu',
        specDetail: '<6s',
      },
      {
        key: '7',
        specName: 'Częstotliwość',
        specDetail: '1 – 10Hz',
      },
      {
        key: '8',
        specName: 'Rozmiar plamki',
        specDetail: 'od 3mm',
      },
      {
        key: '9',
        specName: 'System chłodzący',
        specDetail: 'Chłodzenie wodą i powietrzem',
      },
      {
        key: '10',
        specName: 'Żywotność głowicy',
        specDetail: '1 000 000 impulsów',
      },
      {
        key: '11',
        specName: 'Ekran',
        specDetail: '15’’ LCD',
      },
      {
        key: '12',
        specName: 'Wymiary',
        specDetail: '560 x 600 x 510 mm',
      },
      {
        key: '13',
        specName: 'Waga',
        specDetail: '32 kg',
      },
    ],
  },
  {
    title: 'Laser Gangsta Pico',
    id: 'gangsta-pico',
    video: {
      title: 'pico sekunda',
      url:
        'https://videos.ctfassets.net/684e5sx201ld/9cUOfP4KoF8gw8khGpyvC/1371d9cdc5fbd3cf7956eeab06731d5e/pico_sekunda.mp4',
    },
    images: ['pico1', 'pico2'],
    price: '130 000 zł netto + 23% Vat',
    description: (
      <>
        <Typography.Paragraph>
          Laser pikosekundowy Gangsta Pico to urządzenie do usuwania pigmentów,
          generującego impulsy o czasie trwania mierzonym w pikosekundach, co
          oznacza zwiększenie skuteczności usuwania pigmentów, w tym pigmentów
          kolorowych oraz skrócenie czasu uzyskania zadowalającego efektu
          zabiegowego.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Skutkiem działania impulsu mierzonego w pikosekundach generowanego
          przez laser pikosekundowy jest głównie efekt fotoakustyczny
          ograniczony do cząstki usuwanego pigmentu z niewielką komponentą
          fototermiczną, w przeciwieństwie do efektu generowanego przez impuls
          lasera Q- Switch mierzonego w nanosekundach, gdzie występuje
          fototermoliza poprzez reakcję fototermiczną, z mniejszym efektem
          fotomechanicznym, a skutki reakcji fototermicznej przenoszone są na
          tkanki otaczające pigment. Dzięki użyciu lasera pikosekundowego
          bardziej skoncentrowana energia dostarczana jest do chromoforu (którym
          jest pigment), co daje możliwość zastosowania mniejszych wartości
          fluencji, a to przekłada się na bezpieczeństwo zabiegu.
        </Typography.Paragraph>
      </>
    ),
    benefits: [
      {
        title:
          'Gangsta Pico to urządzenie produkowane na nasze zamówienie i zgodnie z naszymi wytycznymi',
      },
      {
        title:
          'Wysokiej jakości kryształ neodymowo yagowy w połączeniu z mocną ksenonową lampą pozwalają na pracę na stabilnych parametrach podczas całej sesji usuwania,',
      },
      {
        title:
          'Intuicyjny układ sterowania laserem bezpośrednio wpływa na jakość pracy, zwiększając ją kilkukrotnie,',
      },
      {
        title:
          'Przedłużona żywotność i wydajność urządzenia poprzez zastosowanie wysokiej jakości elementów systemu kontroli i ochrony. Ochrona kryształu w czasie rzeczywistym, dzięki wysokiej jakości czujnikom temperatury oraz czujnikom przepływu cieczy,',
      },
      {
        title:
          'Wielostopniowy system chłodzenia woda-powietrze o zwiększonej wydajności',
      },
      {
        title: 'Intuicyjna obsługa, nowoczesny design',
      },
    ],
    mainDesription: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Usuwanie makijaży permanentnych i tatuaży: Laser Gangsta Pico skutecznie usuwa tatuaże oraz makijaże permanentne emitując krótkie impulsy światła mierzone w picosekundach. Impulsy pochłaniane są w sposób selektywny przez barwnik. Następuje niszczenie barwnika, a jego mikrofragmenty są pochłaniane i wydalane przez układ limfatyczny. Skuteczny dla wszystkich typów skór. Usuwa pigment na głębokości 5mm. Każdy kolor barwnika absorbuje inną długość fali. Gangsta Pico generuje fale o długości: 1064nm – na wszystkie ciemne kolory, dzięki zastosowaniu kryształu KTP – 532nm – na odcienie czerwieni, 585nm, 650nm.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    mainContentItems: [
      {
        title: 'Usuwanie przebarwień',
        description:
          'Usuwanie przebarwień laserem picosekundowym jest możliwe dzięki wysokiej absorbcji promieniowania laserowego przez melaninę. Dla redukcji przebarwień naskórkowych i naskórkowo-skórnych rekomendowane jest stosowanie wiązki 532nm, a przebarwień skórnych - wiązki 1064nm.',
      },
      {
        title: 'Peeling węglowy/Carbon peeling',
        description:
          'Zabieg polegający na usunięciu promieniowaniem laserowym ze skóry twarzy, szyi i dekoltu koloidalnej pasty węglowej. Drobinki węgla wnikają w nierówności oraz pory skóry, a następnie absorbują energię światła lasera ND-YAG, która powoduje natychmiastowe odparowanie preparatu wraz z zanieczyszczeniami. Efektem jest oczyszczenie i zwężenie porów skóry, ujednolicenie kolorytu, stymulacja produkcji kolagenu, zmniejszenie stanu zapalnego, stymulacja komórek skóry do odbudowy oraz ustabilizowanie wydzielania sebum.',
      },
      {
        title: 'Odnowa biologiczna skóry/bio-regeneracja',
        description:
          'Laser Gangsta Pico ma dodatkowy tryb pracy GENESIS. Załączona w zestawie soczewka HONEYCOMB, tzw. Plaster Miodu. Odnowa biologiczna to działania mające na celu przyśpieszenie procesów regeneracyjnych i poprawę zdrowia, w tym przypadku skóry.',
      },
      {
        title: 'Zabieg Genesis',
        description:
          'Jest to nie tylko zabieg odmładzający skórę, ale także pomaga jej w regeneracji, stymuluje produkcję kolagenu, powala pozbyć się najczęstszych problemów skórnych, takich jak: pękające naczynka, rumień, rozszerzone pory czy przebarwienia. Wiązka lasera o fali 1064nm z soczewką GENESIS rozgrzewa górne warstwy skóry właściwej, co stymuluje fibroblasty do produkcji nowych włókien kolagenowych i elastyny. Oddziaływuje także na hemoglobinę – zwęża rozszerzone ściany naczyń krwionośnych, tym samym wzmacnia jej unaczynienie, poprawiając jej koloryt, zmniejszając zaczerwienienia, przebarwienia i stany zapalne. Genesis to doskonały zabieg rewitalizujący skórę. Tuż po zabiegu widać napięcie skóry, znaczną poprawę jej wyglądu i kolorytu. Tuż po obserwujemy zmniejszenie mniejszych zmarszczek oraz płytkich przebarwień. Pełne efekty zabiegowe zaobserwujemy po 3-6 miesiącach w zależności od czasu produkcji nowych włókien kolagenowych przez organizm. Zależy to od wielu czynników, m.in. wiek, tryb życia, itp.',
      },
    ],
    mainContentItemColumns: 2,
    specTableRows: [
      {
        key: '1',
        specName: 'Model',
        specDetail: 'Gangsta Pico',
      },
      {
        key: '2',
        specName: 'Moc',
        specDetail: '1000W',
      },
      {
        key: '3',
        specName: 'Długość fali',
        specDetail: '1064nm, 532nm, opcjonalnie: 585nm, 650nm',
      },
      {
        key: '4',
        specName: 'Maksymalna energia',
        specDetail: '2000mJ',
      },
      {
        key: '5',
        specName: 'Moc w monoimpulsie',
        specDetail: 'od 250mJ',
      },
      {
        key: '6',
        specName: 'Czas trwania impulsu',
        specDetail: '550-750ps',
      },
      {
        key: '7',
        specName: 'Częstotliwość',
        specDetail: '1 – 10Hz',
      },
      {
        key: '8',
        specName: 'Rozmiar plamki',
        specDetail: '2-10 mm',
      },
      {
        key: '9',
        specName: 'System chłodzący',
        specDetail: 'Chłodzenie wodą i powietrzem',
      },
      {
        key: '10',
        specName: 'Żywotność głowicy',
        specDetail: '1 000 000 impulsów',
      },
      {
        key: '11',
        specName: 'Wymiary',
        specDetail: '920 x 555 x 1065 mm',
      },
      {
        key: '12',
        specName: 'Waga',
        specDetail: '70 kg',
      },
    ],
    indications: [
      { title: 'trądzik różowaty' },
      { title: 'trądzik zwykły' },
      { title: 'blizny, również potrądzikowe' },
      { title: 'wiotkość skóry' },
      { title: 'rozstępy' },
      { title: 'zmarszczki' },
      { title: 'cienie pod oczami' },
      { title: 'melazma' },
      { title: 'rozszerzone pory' },
      { title: 'rumień' },
      { title: 'płytkie przebarwienia' },
      { title: 'popękane naczynka' },
      { title: 'nierówności w strukturze skóry' },
      { title: 'grzybica paznokci' },
    ],
  },
  {
    title: 'Laser Gangsta Nano Mono kryształ',
    id: 'gangsta-nano-mono',
    video: {
      title: 'nano krysztal',
      url:
        'https://videos.ctfassets.net/684e5sx201ld/6K2V1FTokVG5EwqWT4g5Fa/415e1b949b358fc3da7feafbbdeb1540/nano_krysztal.mp4',
    },
    images: ['nano1', 'nano2'],
    price: '30 000 zł netto + 23% Vat',
    description:
      'Laser nanosekundowy ND-YAG Q-Switch Gangsta Nano to nowa generacja lasera kompaktowego nanosekundowego do usuwania makijaży permanentnych i tatuaży, przebarwień oraz peelingu węglowego.',
    benefits: [
      {
        title:
          'Gangsta Nano to urządzenie produkowane na nasze zamówienie i zgodnie z naszymi wytycznymi',
      },
      {
        title:
          'Wysokiej jakości kryształ neodymowo yagowy w połączeniu z mocną ksenonową lampą pozwalają na pracę na stabilnych parametrach podczas całej sesji usuwania,',
      },
      {
        title: 'Technologia Q-Switch & Intuicyjna obsługa, nowoczesny design.',
      },
      {
        title:
          'Intuicyjny układ sterowania laserem bezpośrednio wpływa na jakość pracy, zwiększając ją kilkukrotnie,',
      },
      {
        title:
          'Przedłużona żywotność i wydajność urządzenia poprzez zastosowanie wysokiej jakości elementów systemu kontroli i ochrony. Ochrona kryształu w czasie rzeczywistym, dzięki wysokiej jakości czujnikom temperatury oraz czujnikom przepływu cieczy',
      },
      {
        title:
          'Wielostopniowy system chłodzenia woda-powietrze o zwiększonej wydajności',
      },
    ],
    mainDesription: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Usuwanie makijaży permanentnych i tatuaży: Laser Gangsta Nano skutecznie usuwa tatuaże oraz makijaże permanentne emitując krótkie impulsy światła mierzone w nanosekundach. Impulsy pochłaniane są w sposób selektywny przez barwnik. Następuje niszczenie barwnika, a jego mikrofragmenty są pochłaniane i wydalane prze układ limfatyczny. Skuteczny dla wszystkich typów skór. Usuwa pigment na głębokości 5mm. Każdy kolor barwnika absorbuje inną długość fali.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    mainContentItems: [
      {
        title: 'Gangsta Nano generuje fale o długości',
        description: (
          <ul>
            <li>1064nm – na wszystkie ciemne kolory</li>
            <li>
              dzięki zastosowaniu kryształu KTP – 532nm – na odcienie czerwieni
            </li>
          </ul>
        ),
      },
      {
        title: 'Usuwanie przebarwień',
        description:
          'Usuwanie przebarwień laserem ND-YAG Q-Switch jest możliwe dzięki wysokiej absorbcji promieni lasera w melaninie.',
      },
      {
        title: 'Peeling węglowy/Carbon peeling',
        description:
          'Zabieg polegający na usunięciu promieniowaniem laserowym ze skóry twarzy, szyi i dekoltu koloidalnej pasty węglowej. Drobinki węgla wnikają w nierówności oraz pory skóry, a następnie absorbują energię światła lasera ND-YAG, która powoduje natychmiastowe odparowanie preparatu wraz z zanieczyszczeniami. Efektem jest oczyszczenie i zwężenie porów skóry, ujednolicenie kolorytu, stymulacja produkcji kolagenu, zmniejszenie stanu zapalnego, stymulacja komórek skóry do odbudowy oraz ustabilizowanie wydzielania sebum.',
      },
    ],
    mainContentItemColumns: 3,
    mainExtraContent:
      'W cenie urządzenia dwudniowe szkolenie z zakresu bezpiecznych technik usuwania makijażu permanentnego i tatuażu oraz peelingu węglowego.',
    specTableRows: [
      {
        key: '1',
        specName: 'Model',
        specDetail: 'Gangsta Nano',
      },
      {
        key: '2',
        specName: 'Moc',
        specDetail: '1000W',
      },
      {
        key: '3',
        specName: 'Długość fali',
        specDetail: '1064nm, 532nm',
      },
      {
        key: '4',
        specName: 'Maksymalna energia',
        specDetail: '2000mJ',
      },
      {
        key: '5',
        specName: 'Moc w monoimpulsie',
        specDetail: '220mJ',
      },
      {
        key: '6',
        specName: 'Czas trwania impulsu',
        specDetail: '<3s',
      },
      {
        key: '7',
        specName: 'Częstotliwość',
        specDetail: '1 – 10Hz',
      },
      {
        key: '8',
        specName: 'Rozmiar plamki',
        specDetail: 'od 3mm',
      },
      {
        key: '9',
        specName: 'System chłodzący',
        specDetail: 'Chłodzenie wodą i powietrzem',
      },
      {
        key: '10',
        specName: 'Żywotność głowicy',
        specDetail: '1 000 000 impulsów',
      },
      {
        key: '11',
        specName: 'Ekran',
        specDetail: '8,4’’ LCD',
      },
      {
        key: '12',
        specName: 'Wymiary',
        specDetail: '470 x 470 x 290 mm',
      },
      {
        key: '13',
        specName: 'Waga',
        specDetail: '21 kg',
      },
    ],
  },
];

export const GangstaLasers: React.FC = () => {
  const gangstaLasersImages = useStaticQuery(graphql`
    query {
      pico1: file(relativePath: { eq: "genesis-plaster-miodu.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pico2: file(relativePath: { eq: "pico-sekunda-genesis.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nano1: file(relativePath: { eq: "nano-krysztal.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nano2: file(relativePath: { eq: "nano-krysztal-1.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nanoX21: file(relativePath: { eq: "nano-krysztal-x2.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nanoX22: file(relativePath: { eq: "nano-krysztal-x2-1.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { activeTab, onTabChange } = useTabs({
    key: 'laser',
    defaultTab: GANGSTA_LASERS_DATA[0].id,
  });

  return (
    <Tabs
      size="large"
      className="gangsta-lasers"
      activeKey={activeTab}
      onChange={onTabChange}
    >
      {GANGSTA_LASERS_DATA.map(item => (
        <Tabs.TabPane
          tab={<Typography.Title level={4}>{item.title}</Typography.Title>}
          key={item.id}
        >
          <Box className="gangsta-lasers-content">
            <Row gutters={['2rem', '2rem']}>
              <Col span={{ sm: 24, md: 12 }} className="overview-section">
                {typeof item.description === 'string' ? (
                  <Typography.Paragraph>
                    {item.description}
                  </Typography.Paragraph>
                ) : (
                  item.description
                )}

                <hr />

                <Typography.Title level={4}>
                  <strong className="text-primary">{item.price}</strong>
                </Typography.Title>
              </Col>
              <Col span={{ sm: 24, md: 12 }}>
                {item.video && <Video {...item.video} />}
              </Col>
            </Row>

            <Box>
              <SectionHeading title={'Co nas wyróżnia'} titleLevel={4} />
              <GridListComponent columns={3} items={item.benefits} />
            </Box>

            <Box>
              <SectionHeading
                title={'Zastosowanie'}
                description={item.mainDesription}
                titleLevel={4}
              />
              <GridListComponent
                columns={item.mainContentItemColumns}
                items={item.mainContentItems}
              />
            </Box>

            <Box>
              <SectionHeading title={'Galeria & Prezentacja'} titleLevel={4} />
              <Row gutters={['2rem', '2rem']} style={{ padding: '2rem 0' }}>
                {item.images.map((imageKey, index) => (
                  <Col span={{ sm: 24, md: 12 }} key={index}>
                    <Box className="image-section-item">
                      <Img
                        fluid={
                          gangstaLasersImages?.[imageKey]?.childImageSharp
                            ?.fluid
                        }
                      />
                    </Box>
                  </Col>
                ))}
              </Row>
            </Box>

            <Box>
              <SectionHeading title={'Pozostałe informacje'} titleLevel={4} />
              <Box className="collapsible-list" style={{ padding: '2rem 0' }}>
                <Collapse
                  expandIconPosition="right"
                  ghost={true}
                  defaultActiveKey={['Specyfikacja techniczna']}
                >
                  <Collapse.Panel
                    header={'Specyfikacja techniczna'}
                    key={'Specyfikacja techniczna'}
                  >
                    <Table
                      columns={[
                        {
                          title: '',
                          dataIndex: 'specName',
                          key: 'specName',
                        },
                        {
                          title: '',
                          dataIndex: 'specDetail',
                          key: 'specDetail',
                        },
                      ]}
                      dataSource={item.specTableRows}
                      pagination={false}
                      bordered
                      showHeader={false}
                    />
                  </Collapse.Panel>
                  {item.indications && (
                    <Collapse.Panel header={'Wskazania'} key={'Wskazania'}>
                      <List
                        dataSource={item.indications}
                        renderItem={item => (
                          <List.Item>
                            <Typography.Text>{item.title}</Typography.Text>
                          </List.Item>
                        )}
                      />
                    </Collapse.Panel>
                  )}
                </Collapse>
              </Box>
            </Box>
          </Box>
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default GangstaLasers;
