import React, { memo } from 'react';

import { useCustomSectionData } from '../data/hooks/use-custom-section-data';
import { DataType } from '../data/model/common';
import { CustomSectionSubsectionsData } from '../data/model/custom-section';
import { makeThemeClassName } from '../utils';
import Button from './button';
import CardGroup from './card-group';
import CollapsibleList from './collapsible-list';
import SectionHeading from './common/section-heading';
import GangstaLasers from './gangsta-lasers';
import Grid from './grid';
import GridList from './grid-list';
import { Box, Container, Section } from './layout';
import Map from './map';
import NumbersList from './numbers-list';
import Testimonials from './testimonials';

interface CustomSectionProps {
  id: string;
}

const SUBSECTIONS_COMPONENTS: {
  [TType in CustomSectionSubsectionsData['type']]: React.FC<{ id: string }>;
} = {
  [DataType.CardGroup]: CardGroup,
  [DataType.Grid]: Grid,
  [DataType.GridList]: GridList,
  [DataType.Testimonials]: Testimonials,
  [DataType.CollapsibleList]: CollapsibleList,
  [DataType.NumbersList]: NumbersList,
  [DataType.Map]: Map,
};

const CustomSection: React.FC<CustomSectionProps> = ({ id }) => {
  const { data } = useCustomSectionData(id);

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'custom-section': true,
        'section-padding': true,
      })}
    >
      <Container>
        <SectionHeading title={data.title} description={data.description} />

        {data.tempEnableGangstaLasers ? (
          <GangstaLasers />
        ) : (
          data.subsections?.map((subsection, index) => {
            const Component = SUBSECTIONS_COMPONENTS[subsection.type];

            return (
              <div key={index}>
                <Component id={subsection.id} />
              </div>
            );
          })
        )}

        {data.button && (
          <Box justify="center" align="middle">
            <Button id={data.button?.id} />
          </Box>
        )}
      </Container>
    </Section>
  );
};

export default memo(CustomSection);
