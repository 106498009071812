import React from 'react';

import { useMapData } from '../data/hooks';

interface MapProps {
  id: string;
}

const Map: React.FC<MapProps> = ({ id }) => {
  const { data } = useMapData(id);

  return (
    <iframe
      width="600"
      height="450"
      frameBorder="0"
      allowFullScreen
      style={{ border: 0, width: '100%', height: '500px' }}
      src={data.googleApiUrl}
    ></iframe>
  );
};

export default Map;
