/* eslint-disable simple-import-sort/sort */
import '../styles/grid-list.scss';

import React from 'react';

import { useGridListData, GridListItemData } from '../data';
import GridListItem, { GridListItemComponent } from './grid-list-item';
import { Col, Row } from './layout';

interface GridListProps {
  id: string;
}

interface GridListComponentProps {
  title?: string;
  columns: number;
  items: GridListItemData[] | GridListItemData['data'][];
}

interface GridListContainerProps extends GridListComponentProps {
  renderItem: (args: {
    index: number;
    item: GridListItemData | GridListItemData['data'];
  }) => React.ReactNode;
}

const GridListContainer: React.FC<GridListContainerProps> = ({
  columns,
  items,
  renderItem,
}) => {
  const colSpan = 24 / columns;

  return (
    <Row className="grid-list" gutters="2rem">
      {items.map((item, index) => (
        <Col span={{ sm: 24, lg: colSpan }} key={index}>
          {renderItem({ item, index })}
        </Col>
      ))}
    </Row>
  );
};

export const GridListComponent: React.FC<GridListComponentProps> = props => (
  <GridListContainer
    {...props}
    renderItem={({ index, item }) => (
      <GridListItemComponent
        index={index}
        {...(item as GridListItemData['data'])}
      />
    )}
  />
);

export const GridList: React.FC<GridListProps> = ({ id }) => {
  const { data } = useGridListData(id);

  return (
    <GridListContainer
      {...data}
      renderItem={({ index, item }) => (
        <GridListItem id={(item as GridListItemData).id} index={index} />
      )}
    />
  );
};

export default GridList;
