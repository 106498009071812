import '../styles/sales-section.scss';

import { Carousel } from 'antd';
import React from 'react';

import { useSalesSectionData } from '../data/hooks';
import { makeThemeClassName } from '../utils';
import { Container, Section } from './layout';
import SalesSectionItem from './sales-section-item';

interface SalesSectionProps {
  id: string;
}

const SalesSection: React.FC<SalesSectionProps> = ({ id }) => {
  const { data } = useSalesSectionData(id);

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'theme-secondary': true,
        'sales-section': true,
      })}
    >
      <Container>
        <Carousel>
          {data.items.map((item, index) => (
            <div key={index}>
              <SalesSectionItem id={item.id} key={index} />
            </div>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
};

export default SalesSection;
