import '../styles/interleaving-images-section.scss';

import React, { memo } from 'react';

import { useInterleavingImagesSectionData } from '../data/hooks/use-interleaving-images-section-data';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';

interface InterleavingImagesSectionProps {
  id: string;
}

const InterleavingImagesSection: React.FC<InterleavingImagesSectionProps> = ({
  id,
}) => {
  const data = useInterleavingImagesSectionData(id);
  console.log(data, 'data InterleavingImagesSection');
  return (
    <Section
      className={makeThemeClassName(data?.data?.theme, {
        'interleaving-images-section': true,
      })}
    >
      <Container>
        {data?.data?.items?.map(({ data: itemData }, index) => {
          const indexIsOdd = index % 2 !== 0;

          return (
            <Row className="interleaving-images-section__item" key={index}>
              <Col
                span={{ sm: 24, lg: 12 }}
                order={{ sm: 1, lg: indexIsOdd ? 2 : 1 }}
                style={{
                  sm: {},
                  lg: { padding: indexIsOdd ? '0 0 0 2rem' : '0 2rem 0 0' },
                }}
              >
                <Image id={itemData.image.id} />
              </Col>

              <Col
                span={{ sm: 24, lg: 12 }}
                order={{ sm: 2, lg: indexIsOdd ? 1 : 2 }}
                style={{
                  sm: {},
                  lg: { padding: !indexIsOdd ? '0 0 0 2rem' : '0 2rem 0 0' },
                }}
              >
                <Box justify="center" direction="column" fullHeight>
                  <h3>{itemData.title}</h3>
                  {contentfulJSONToReactComponents(itemData.description.json)}
                </Box>
              </Col>
            </Row>
          );
        })}
      </Container>
    </Section>
  );
};

export default memo(InterleavingImagesSection);
