import '../styles/gallery-section.scss';

import { Tabs } from 'antd';
import React, { useRef } from 'react';

import { useGallerySectionData } from '../data/hooks';
import { makeThemeClassName } from '../utils';
import SectionHeading from './common/section-heading';
import GallerySectionItem from './gallery-section-item';
import { Box, Container, Section } from './layout';

interface GallerySectionProps {
  id: string;
}

const GallerySection: React.FC<GallerySectionProps> = ({ id }) => {
  const { data } = useGallerySectionData(id);

  const containerRef = useRef<HTMLDivElement>();

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'gallery-section': true,
        'section-padding': true,
      })}
    >
      <Container>
        <SectionHeading title={data.title} description={data.description} />

        <div ref={containerRef}>
          <Box justify="center" align="middle" direction="column">
            <Tabs size="large">
              {data.items.map((item, index) => (
                <Tabs.TabPane tab={item.data.title} key={item.id}>
                  <GallerySectionItem
                    id={item.id}
                    key={index}
                    containerRef={containerRef}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Box>
        </div>
      </Container>
    </Section>
  );
};

export default GallerySection;
