import '../../styles/cards.scss';

import { Typography } from 'antd';
import React, { useState } from 'react';

import { classNameMap } from '../../utils';
import Button, { ButtonProps } from '../button';
import { Col, Row } from '../layout';

interface Card {
  type?: string;
  title: string;
  subTitle?: string;
  content: React.ReactNode | string;
  buttonId?: string;
  renderButton?: (
    buttonProps: ButtonProps['defaultProps'],
    cardIndex: number,
  ) => React.ReactNode;
}

interface CardProps {
  item: Card;
  shadow?: boolean;
  align?: CardsProps['align'];
  cardIndex: number;
}

const Card: React.FC<CardProps> = ({
  item,
  shadow = true,
  align = 'center',
  cardIndex,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const buttonProps: ButtonProps['defaultProps'] = {
    type: isHovered ? 'primary' : 'default',
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classNameMap({
        card: true,
        'card--horizontal': item.type === 'horizontal',
        [`card--${align}`]: true,
        'card--shadow': shadow,
        'theme-secondary': isHovered,
      })}
    >
      {item.subTitle && (
        <Typography.Paragraph className="card__sub-title">
          {item.subTitle}
        </Typography.Paragraph>
      )}

      <Typography.Title level={4} className="text-uppercase">
        {item.title}
      </Typography.Title>

      <Typography.Paragraph>{item.content}</Typography.Paragraph>

      {item.buttonId && (
        <Button id={item.buttonId} defaultProps={buttonProps} />
      )}

      {item.renderButton && item.renderButton(buttonProps, cardIndex)}
    </div>
  );
};

interface CardsProps {
  items: Card[];
  align?: 'center' | 'start';
}

const Cards: React.FC<CardsProps> = ({ items, align }) => {
  return (
    <div className="cards">
      <Row
        justify="center"
        style={{ width: '100%' }}
        gutters={{ md: ['0rem', '2rem'] }}
      >
        {items.map((card, index) => (
          <Col
            span={{ sm: 24, md: 6 }}
            key={index}
            style={{ sm: { margin: '1rem 0' }, md: { margin: '0 1rem' } }}
          >
            <Card item={card} cardIndex={index} align={align} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Cards;
