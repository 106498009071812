/* eslint-disable simple-import-sort/sort */
import { useEffect, useState } from 'react';
import { useLocation, navigate } from '@reach/router';

export const useTabs = ({ defaultTab = '0', key = 'active-tab' }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(defaultTab.toString());

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabIndex = searchParams.get(key);
    if (tabIndex) {
      setActiveTab(tabIndex);
    }
  }, [location.search]);

  const onTabChange = (newActiveTab: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, newActiveTab);
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setActiveTab(newActiveTab);
  };

  return { activeTab, onTabChange };
};
