import { Typography } from 'antd';
import React from 'react';

import { ServiceType } from '../data';
import { usePricesSectionItemData } from '../data/hooks';
import { useTrainingDetailsSectionData } from '../data/hooks/use-training-details-section-data';
import { useTreatmentDetailsSectionData } from '../data/hooks/use-treatment-details-section-data';
import { Box } from './layout';

interface PricesSectionProps {
  id: string;
}

const PricesSectionItem: React.FC<PricesSectionProps> = ({ id }) => {
  const { data } = usePricesSectionItemData(id);

  const servicesDetails = {
    [ServiceType.Treatments]: useTreatmentDetailsSectionData()?.list,
    [ServiceType.Trainings]: useTrainingDetailsSectionData()?.list,
  };

  return (
    <>
      {data.items.map((item, index) => (
        <div key={index} className="prices-section-item">
          <Typography.Title level={3}>{item.data.title}</Typography.Title>

          {servicesDetails?.[data.serviceType]
            .filter(
              service => service?.data?.serviceId === item?.data?.serviceId,
            )
            .map((item, index) => (
              <Box
                justify="space-between"
                align="middle"
                key={index}
                className="prices-section-item__price"
              >
                <Typography.Paragraph>{item.data.title}</Typography.Paragraph>
                <Typography.Paragraph>{item.data.price}</Typography.Paragraph>
              </Box>
            ))}
        </div>
      ))}
    </>
  );
};

export default PricesSectionItem;
