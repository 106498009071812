import '../styles/collapsible-list.scss';

import { Collapse } from 'antd';
import React from 'react';

import { useCollapsibleListData } from '../data/hooks';
import { contentfulJSONToReactComponents } from '../utils';
import { Box } from './layout';

interface CollapsibleList {
  id: string;
}

const CollapsibleList: React.FC<CollapsibleList> = ({ id }) => {
  const { data } = useCollapsibleListData(id);
  return (
    <Box
      justify="center"
      align="middle"
      direction="column"
      fullHeight
      className="collapsible-list"
    >
      <Collapse expandIconPosition="right" ghost={true}>
        {data.items.map(item => (
          <Collapse.Panel header={item.data.title} key={item.id}>
            <Box>{contentfulJSONToReactComponents(item.data.content.json)}</Box>
          </Collapse.Panel>
        ))}
      </Collapse>
    </Box>
  );
};

export default CollapsibleList;
