import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useEffect, useRef, useState } from 'react';

import { useGallerySectionItemData } from '../data/hooks';
import { classNameMap } from '../utils/tsx';
import GallerySectionItemImage from './gallery-section-item-image';
import { Col, Row } from './layout';

interface GallerySectionItemProps {
  id: string;
  containerRef: React.MutableRefObject<HTMLDivElement>;
}

const GallerySectionItem: React.FC<GallerySectionItemProps> = ({
  id,
  containerRef,
}) => {
  const { data } = useGallerySectionItemData(id);

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const carouselRef = useRef<CarouselRef>();

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goTo(selectedItemIndex, true);
    }
  }, [selectedItemIndex]);

  return (
    <>
      <Carousel ref={carouselRef} dots={false}>
        {data.items.map((item, index) => (
          <GallerySectionItemImage id={item.id} key={index} />
        ))}
      </Carousel>

      <Row gutters={['2rem', '2rem']} hidden={{ sm: true, md: false }}>
        {data.items.map((item, index) => (
          <Col span={{ sm: 24, md: 6 }} key={index}>
            <button
              onClick={() => {
                setSelectedItemIndex(index);
                containerRef?.current.scrollIntoView(true);
              }}
              className={classNameMap({
                'gallery-section__item': true,
                'gallery-section__item--selected': selectedItemIndex === index,
              })}
            >
              <GallerySectionItemImage id={item.id} />
            </button>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GallerySectionItem;
