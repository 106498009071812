import React from 'react';

import { useCardGroupData } from '../data';
import Cards from './common/cards';

interface CardGroupProps {
  id: string;
}

const CardGroup: React.FC<CardGroupProps> = ({ id }) => {
  const { data } = useCardGroupData(id);

  return (
    <Cards
      items={data.items.map(item => ({
        title: item.data.title,
        content: item.data.description,
        type: item.data.type,
        buttonId: item.data.button.id,
      }))}
    />
  );
};

export default CardGroup;
