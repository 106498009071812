import { Modal as AntdModal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { useModalData } from '../data/hooks';
import CloseIcon from '../images/close.svg';
import { contentfulJSONToReactComponents } from '../utils/contentful';
import Button from './button';
import Form from './form';
import Image from './image';
import { Col, Row } from './layout';

export interface ModalProps {
  id: string;
  modalId?: symbol;
}

const Modal: React.FC<ModalProps> = ({ id, modalId }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { data } = useModalData(id);

  useEffect(() => {
    if (modalId) {
      setVisible(true);
    }
  }, [modalId]);

  return (
    <AntdModal
      className="company-modal"
      centered
      destroyOnClose
      closeIcon={<CloseIcon />}
      width={800}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Row>
        <Col
          span={{ sm: 24, md: data?.image ? 12 : 24 }}
          style={{ padding: '3rem' }}
        >
          {data.subTitle && <small>{data.subTitle}</small>}

          {data.title && (
            <Typography.Title level={3}>{data.title}</Typography.Title>
          )}

          {data.description &&
            contentfulJSONToReactComponents(data.description.json)}

          {data.form && (
            <Form id={data.form.id} onSuccess={() => setVisible(false)} />
          )}

          {!data.form && data.button && <Button id={data.button.id} />}
        </Col>

        <Col hidden={!data?.image} span={{ sm: 24, md: 12 }}>
          <Image id={data?.image?.id} />
        </Col>
      </Row>
    </AntdModal>
  );
};

export default Modal;
