import '../styles/hero-section.scss';

import { Space, Typography } from 'antd';
import { isNil } from 'lodash';
import React, { memo } from 'react';

import { useHeroSectionData } from '../data/hooks/use-hero-section-data';
import { classNameMap, makeThemeClassName } from '../utils';
import Button from './button';
import Video from './common/video';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';
import { LayoutBreakpoint } from './layout/types';

interface HeroSectionProps {
  id: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ id }) => {
  const { data } = useHeroSectionData(id);

  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const shouldHideHeadingCol = [
    data.title,
    data.description,
    data.goBackButton,
  ].every(isNil);

  const ContainerWrapper = shouldHideHeadingCol ? React.Fragment : Container;

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'hero-section': true,
        'theme-secondary': true,
        'hero-section--full-vh': data.fullHeight,
      })}
    >
      <ContainerWrapper>
        <Row>
          <Col
            hidden={shouldHideHeadingCol}
            span={{ sm: 24, md: 12 }}
            style={{ md: { paddingRight: '2rem' } }}
          >
            <Box
              justify="center"
              direction="column"
              fullHeight
              className="hero-section__heading-box"
              style={{ sm: { padding: '2rem 0' } }}
            >
              <Box style={{ sm: { marginBottom: '1.25rem' } }}>
                {data.goBackButton && (
                  <Button
                    id={data.goBackButton.id}
                    defaultProps={{ type: 'link' }}
                  />
                )}
              </Box>

              <Box style={{ margin: 'auto 0' }}>
                {data.subTitle && (
                  <Typography.Paragraph className="hero-section__sub-title">
                    {data.subTitle}
                  </Typography.Paragraph>
                )}

                {data.title && (
                  <Typography.Title level={1}>{data.title}</Typography.Title>
                )}

                {data.description && (
                  <Typography.Paragraph>
                    {data.description}
                  </Typography.Paragraph>
                )}

                {data.buttons && (
                  <Space
                    wrap
                    direction={isMd ? 'horizontal' : 'vertical'}
                    style={{ width: '100%' }}
                  >
                    {data.buttons.map((button, index) => (
                      <Button
                        id={button.id}
                        key={index}
                        defaultProps={{ block: true }}
                      />
                    ))}
                  </Space>
                )}
              </Box>
            </Box>
          </Col>

          <Col hidden={!data.fitInImage} span={{ sm: 24, md: 12 }}>
            <Box justify="center" direction="column" align="middle" fullHeight>
              <Image id={data.fitInImage?.id} />
            </Box>
          </Col>
        </Row>
      </ContainerWrapper>

      {data.fullFitInImage && (
        <Box
          justify="center"
          direction="column"
          align="middle"
          fullHeight
          className={classNameMap({
            'hero-section__full-fit-in-image': true,
            'hero-section__full-fit-in-image--ful-width': shouldHideHeadingCol,
          })}
        >
          <Image id={data.fullFitInImage.id} />
        </Box>
      )}

      {data.fitInVideo && (
        <Box
          justify="center"
          direction="column"
          align="middle"
          fullHeight
          className={classNameMap({
            'hero-section__full-fit-in-image': true,
            'hero-section__full-fit-in-image--ful-width': shouldHideHeadingCol,
          })}
        >
          <Video {...data?.fitInVideo} />
        </Box>
      )}

      {data.fullFitInVideo && (
        <div className="hero-section--full-vh">
          <Video {...data.fullFitInVideo} />
        </div>
      )}
    </Section>
  );
};

export default memo(HeroSection);
