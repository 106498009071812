import '../styles/prices-section.scss';

import { Tabs } from 'antd';
import React from 'react';

import { usePricesSectionData } from '../data/hooks';
import { makeThemeClassName } from '../utils';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Box, Container, Section } from './layout';
import PricesSectionItem from './prices-section-item';

interface PricesSectionProps {
  id: string;
}

const PricesSection: React.FC<PricesSectionProps> = ({ id }) => {
  const { data } = usePricesSectionData(id);

  return (
    <Section
      className={makeThemeClassName(data.theme, {
        'prices-section': true,
        'section-padding': true,
      })}
    >
      <Container>
        <SectionHeading title={data.title} />

        <Box justify="center" align="middle" direction="column">
          <Tabs size="large">
            {data.items.map((item, index) => (
              <Tabs.TabPane tab={item.data.title} key={index}>
                <PricesSectionItem id={item.id} key={index} />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Button id={data.button.id} />
        </Box>
      </Container>
    </Section>
  );
};

export default PricesSection;
