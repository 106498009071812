import '../styles/phone-input.scss';

import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

interface PhoneInputProps {
  value?: string;
  onChange?: (phoneNumber: string, phoneNumberInternational: string) => void;
  disabled?: boolean;
  enableAutoFocus?: boolean;
}

const removeWhitespacesFromInternationalPhoneNumber = (value: string): string =>
  value?.replace(/ |-/g, '');

const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  disabled,
  enableAutoFocus = false,
}) => (
  <div className="phone-input">
    <ReactPhoneInput
      country={'pl'}
      enableSearch
      autocompleteSearch
      disabled={disabled}
      inputProps={{
        autoFocus: enableAutoFocus,
        required: true,
      }}
      disableSearchIcon
      value={value}
      onChange={(internationalPhoneNumber: string) => {
        if (onChange) {
          onChange(
            removeWhitespacesFromInternationalPhoneNumber(
              internationalPhoneNumber,
            ),
            internationalPhoneNumber,
          );
        }
      }}
    />
  </div>
);

export default PhoneInput;
