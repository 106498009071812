import '../styles/about-section.scss';

import React from 'react';

import { useAboutSectionData } from '../data';
import { contentfulJSONToReactComponents, makeThemeClassName } from '../utils';
import AboutSectionItem from './about-section-item';
import SectionHeading from './common/section-heading';
import Image from './image';
import { Col, Container, Row, Section } from './layout';

interface AboutSectionProps {
  id: string;
}

const AboutSection: React.FC<AboutSectionProps> = ({ id }) => {
  const { data } = useAboutSectionData(id);

  return (
    <>
      <Section
        className={makeThemeClassName(data.theme, {
          'about-section': true,
          'section-padding': true,
        })}
      >
        <Container>
          <Row>
            <Col
              className="about-section__col-main-description"
              span={{ sm: 24, md: 12 }}
              style={{ sm: { padding: '2rem' } }}
            >
              {contentfulJSONToReactComponents(data.description.json)}
            </Col>
            <Col
              className="about-section__col-main-image"
              span={{ sm: 24, md: 12 }}
            >
              <Image id={data.image.id} />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section
        className={makeThemeClassName(data.theme, {
          'section-padding': true,
        })}
      >
        <Container>
          <SectionHeading title={data.title} align="start" />

          <Row gutters={['2rem', '2rem']} hidden={{ md: true }}>
            {data.items.map((item, index) => (
              <Col key={index} span={{ sm: 24 }}>
                <AboutSectionItem id={item.id} />
              </Col>
            ))}
          </Row>

          <Row gutters={['2rem', '2rem']} hidden={{ sm: true, md: false }}>
            {data.items.map((item, index) => (
              <Col key={index} span={{ sm: 24, md: 6 }}>
                <AboutSectionItem id={item.id} />
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default AboutSection;
