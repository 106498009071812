import '../styles/testimonial.scss';

import { Rate } from 'antd';
import React from 'react';

import { useTestimonialData } from '../data';
import TestimonialIconSvg from '../images/testimonial-icon.svg';
import { contentfulJSONToReactComponents } from '../utils/contentful';

interface TestimonialProps {
  id: string;
}

const Testimonial: React.FC<TestimonialProps> = ({ id }) => {
  const { data } = useTestimonialData(id);

  return (
    <div className="testimonial">
      <TestimonialIconSvg />

      <div className="testimonial__content">
        {contentfulJSONToReactComponents(data.content.json)}
      </div>

      <div className="testimonial__rating">
        <Rate value={data.rating} disabled />
      </div>

      <div className="testimonial__author">{data.author}</div>
    </div>
  );
};

export default Testimonial;
